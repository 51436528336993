/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
:root {
  --app-font: Arial, sans-serif; /* Default font */
}

body {
  @apply font-custom;
}
