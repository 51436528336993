// /* 1. Core */
// @import 'core/tailwind-config';

// /* 2. Components */
// @import 'components/example-viewer';
// @import 'components/input';

// /* 3. Overrides */
// @import 'overrides/angular-material';
// @import 'overrides/mat-form-field-outline.scss';
// @import 'overrides/fullcalendar';
// @import 'overrides/highlightjs';
// @import 'overrides/perfect-scrollbar';
// @import 'overrides/quill';

/* 1. Components */
@import 'components/example-viewer';
@import 'components/input';

/* 2. Overrides */
@import 'overrides/angular-material';
@import 'overrides/angular-material-fleet.scss';
@import 'overrides/highlightjs';
@import 'overrides/perfect-scrollbar';
