.selection-hide-checkboxes {
  .mdc-list-item__end {
    display: none !important;
  }
}

.condensed-leading-checkbox-selection-list {
  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    @apply mx-0 #{'!important'};
  }
  .mdc-list-item__primary-text {
    @apply text-sm #{'!important'};
  }
  .mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
    height: 42px !important;
  }
}

.link-button {
  @apply text-sm rounded-md bg-white font-medium text-primary-600 hover:text-primary-500;
}
.no-padding-no-border {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      border-radius: 0px !important;
      padding: 0px !important;
    }
  }
}
.mat-mdc-tab-group {
  .tab-container-no-padding {
    .mat-mdc-tab-body-content {
      height: 100%;
      overflow: auto;
      padding: 24px 0px !important;
    }
    .mat-mdc-tab-header .mat-mdc-tab-label-container {
      margin: 0px !important;
    }
  }
}

.dialog-no-padding-no-border-full-screen {
  height: 100vh !important;
  width: 100vw !important;
  max-width: 100vw !important;
  .mat-dialog-container {
    border-radius: 0px !important;
    padding: 0px !important;
  }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 0px !important;
    padding: 0px !important;
  }
}

.dialog-no-padding {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px !important;
  }
}
.dialog-panel-no-px-no-py {
  .mat-mdc-dialog-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
